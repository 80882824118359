@import url("https://fonts.googleapis.com/css2?family=Asap&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins regular, serif;
    text-align: justify;
}

.main_container {
    display: flex;
    flex-direction: column;

}
.cookies{
    z-index: 999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999 !important;
    align-items: center;
}

.title-cookie{
    font-size:1vw!important;
    font-family: Poppins regular, serif;
}
#rcc-confirm-button{
    position: relative;
    text-align: center;
    width: 150px;
}
.project_card {
    display: flex;
    width: 100%;
    height: 400px;
    padding: 20px;
    gap: 20px;
}

.project_image {
    border-radius: 15px;
}

.project_title {
    -webkit-text-stroke-width: 0.156vw;
    -webkit-text-stroke-color: rgba(0, 189, 214, .48);
    color: transparent;
    font-family: Poppins black, serif;
    font-size: 7.447vw;
    line-height: 11.145vw;
    opacity: .36;
    font-size: 1.5vw;
}

.project_details {
    text-align: left;
    text-align-last: left;
}

.project_link {
    color: #4981be !important;
    font-size: 1.041vw;
    line-height: 1.562vw;
    text-align: left;
    text-align: initial;
    text-decoration: none;
    padding-top: 10px;
}

.items-contact {
    display: flex;
    justify-content: space-evenly;
    gap: 20px;
}

.project_name {
    color: #2d2d2d;
    font-family: Poppins extraBold, serif;
    font-size: 1.52vw;
    line-height: 4.531vw;
    margin: -1.562vw 0 0;
    text-align: left;
    text-align-last: left;
    padding-top: 30px;

}

.bloc-inspire {
    display: flex;
    /*padding-top: 70px;*/
    justify-content: space-around;
    padding: 0 20px;
}

.blc-text {
    color: #505050;
    font-family: Poppins bold, serif;
    font-size: 1.822vw;
    line-height: 3.593vw;
    margin: 0 0 0.885vw;
    position: relative;
    text-align: left;

}

.title-section.expertise.active h1::before {
    background: #f07f18;
}

.title-section.mission.active h1::before {
    background: #f07f18;
}

.title-section.secteur.active h1::before {
    background: #f07f18;
}

.title-section.intern h1::before {
    background: #4faea2;
}

.blc-text:after {
    background-color: #4981be;
    content: "";
    display: block;
    height: 0.26vw;
    position: absolute;
    top: 3.1vw;
    width: 2.552vw;
}

.title-1:before {
    background-color: #4981be;
    content: "";
    display: block;
    height: 0.677vw;
    left: 6.5vw;
    position: absolute;
    top: 2.3vw;
    width: 7.708vw;
    z-index: -9;
}

.title-2:before {
    background-color: #f07f18;
    content: "";
    display: block;
    height: 0.677vw;
    left: 2.5vw;
    position: absolute;
    top: 2.3vw;
    width: 7.708vw;
    z-index: -9;
}

.about_us_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

.detail-text {
    text-align: justify;
    color: #707070;
    font-family: Poppins regular, serif;
    font-size: 1.2vw;
    line-height: 1.51vw;
}

.detail-card-inspire  {
    font-family: Poppins regular, serif;
    color: #2d2d2d;
    font-size: 0.9vw;
    text-align: justify;

}
.detail-card-inspire.service{

    height: 180px;

}

.card-details {
    display: flex;
    height: 100%;
    /*justify-content: space-between;*/
    flex-direction: column;
}

.bloc_tech {
    display: flex;
    flex-direction: row;
    align-self: end;
    gap: 10px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}

.tech-image {
    width: 5vw;
    height: 5.5vh;
    object-fit: contain;
    cursor: pointer;

}

.bloc-card {
    gap: 15px;
    padding-top: 15px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

}

.bloc_tech-front {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 10px;
    width: 100%;
    position: relative;
    margin-left: 0;
    margin-right: 0;

}

.bloc-card-mission {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    gap: 15px;

}

.card-inspire {
    /*background-color: whitesmoke;*/
    /*border-radius: 0.52vw;*/
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-sizing: border-box;
    padding: 2.708vw 2.302vw 1.093vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 0.5vw;
    justify-content: space-evenly;
}

.bloc-card-mission .card-inspire {
    height: 54.031vh;
}

.ant-modal-close-x {
    display: none !important;
}

.card-inspire img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-inspire .headDiv {
    width: 100%;
    height: 200px;
}

.card-inspire .headDivMission {
    /*width: 100%;*/
    height: 10%;
}

.card-inspire .botDivMission {
    width: 100%;
    height: 80%;
}

.card-inspire .headDivMission img {
    width: 100px;
    height: 53px;
    object-fit: contain;
}

.card-inspire .botDiv {
    width: 100%;
    height: 55%;
}

.card-inspire .botDiv .title {
    display: flex;
    flex-direction: row;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 5px;
}

.card-inspire .botDiv .title .second {
    font-size: 1.2vw;
    color: #4981be;
    text-decoration: underline;
    position: relative;
    bottom: 8px;
}

.card-inspire .botDiv .title .last_char {
    font-size: 1.2vw;
    color: #4981be;
    text-decoration: underline;
    position: relative;
    bottom: 8px;
}

li {
    position: relative;
    left: 17px;
    font-size: 0.9vw;
    text-align: justify;
    font-family: Poppins regular, serif;
}

ul {
    font-size: 0.9vw;
    text-align: justify;
    font-family: Poppins regular, serif;
}

.title-card-inspire {
    font-family: Poppins bold, serif;
    margin: 0 0 0.885vw;
    text-transform: uppercase;
    font-size: .8vw;
}

.img-inspire {
    width: 39.635vw;
}

.title-service {
    color: #2d2d2d;
    font-family: Poppins extraBold, serif;
    font-size: 2.604vw;
    line-height: 3.958vw;
    text-transform: uppercase;
}

.sub-title-service {
    color: black;
    font-family: Poppins semiBold, serif;
    font-size: 1.323vw;
    line-height: 2.344vw;
    margin: 0 0 3.229vw;
}

#first {
    display: flex;
    justify-content: space-evenly;
    /*padding: 4.25vw 0 7vw;*/
    position: relative;
    align-self: baseline !important;

}

#second {
    display: flex;
    justify-content: space-evenly;
    padding: 4.25vw 0 7vw;
    position: relative;
    align-self: baseline !important;
    gap: 50px;

}

.card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 30px;
    align-self: center;
    /*width: 200px;*/
    position: relative;
    /*left: 17vw;*/
}

.logoService {
    width: 100px;
    height: 100px;
    border-radius: 20px;
}

.card {
    gap: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    height: auto;
}


.card-title {
    color: black;
    text-align: center;
    /*width: calc(100% - 5vw);*/
    font-family: Poppins bold, serif;
    margin: 0 0 0.885vw;
    text-transform: uppercase;
    font-size: .8vw;
    position: relative;
}

.card-title.g::first-letter {
    font-size: 1.2vw;
    color: #4981be;
    text-decoration: underline;
}

.card-title.r::first-letter {
    font-size: 1.2vw;
    color: #f07f18;
    text-decoration: underline;
}

.card-title.a::first-letter {
    font-size: 1.2vw;
    color: #4faea2;
    text-decoration: underline;
}


.title-propos {
    font-family: Poppins extraBold, serif;
    font-size: 2.604vw;
    line-height: 3.958vw;
    text-transform: uppercase;
    color: #2d2d2d;
    margin: unset;
}

.countContainer {
    position: relative;
    bottom: -10px;
}

.logoCount {
    height: 5.416vw;
    width: 5.416vw;
}

.divCount {
    display: flex;
    align-items: center;
    gap: 10px;
}

.divCountDetails {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    width: 50%;
}

.titleCount {

    color: #7e7d7d;
    font-family: Poppins regular, serif;
    font-size: 1.302vw;
}

.count {
    font-family: Poppins bold, serif;
    font-size: 2.343vw;
    line-height: 2.604vw;
}

.countPlus {
    font-family: Poppins bold, serif;
    font-size: 1.343vw;
    line-height: 2.604vw;
}

.primary-btn {
    background-color: #4981be;
    border: none;
    border-radius: 1.719vw;
    color: white;
    cursor: pointer;
    display: block;
    font-family: Poppins regular, serif;
    font-size: 1.25vw;
    height: 3.385vw;
    margin: 0 auto 0 0;
    outline: none;
    padding: 0 3.958vw;
    position: relative;
    left: 30%;
}

body {
    background: white !important;

}

.left-sect {
    translate: none;
    rotate: none;
    scale: none;
    opacity: 1;
    transform: translate(0px, 0px);
    display: flex;
    flex-direction: column;
    align-items: baseline;
    align-self: center;

}

.title-1 {
    position: relative;
    right: 20px;
    color: black;
    font-family: Poppins bold, serif;
    font-size: 2.2vw;
    line-height: 2.9vw;
    text-align: left;
}

.title-2 {
    position: relative;
    right: 20px;
    color: black;
    font-family: Poppins bold, serif;
    font-size: 2.2vw;
    line-height: 2.9vw;
    text-align: left;
}

#p {
    color: #707070;
    font-family: Poppins regular, serif;
    font-size: 1.2vw;
    line-height: 2vw;
    margin: 0 0 1.771vw;
    text-align: justify;
}

.img-mission {
    height: 400px;
}

.clients-image {
    /*width: 100px;*/
}

section {
    /*min-height: 100vh !important;*/
    position: relative !important;
    /*top: 60px ;*/
    /*scrosll-snap-align: center!important;*/
    /*padding-top:9vh!important;*/


}

#first:nth-child(1) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

section:nth-child(1) {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-evenly !important;
    height: auto !important;
    gap: 5vh !important;
}

section:nth-child(2) {
    /*color: #e0ffff;*/
    /*background-image: url("../../assets/img/Sans_titre-aNAtSzCjp-transformed.jpeg");*/
    background-repeat: no-repeat;
    background-size: cover;
}

.secteur-bloc {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px;
    gap: 20px;


}

.secteur-card {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.last {
    top: -130px !important;
}

.left-inspire {
    z-index: 999;
    position: relative;
}

section h1 {
    font-size: 3rem;
    margin: 20px;
}

section h2 {
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
}

section .text-container {
    display: flex;
}

section .text-container .text-box {
    margin: 20px;
    padding: 20px;
    background: #00c2cb;
}

section .text-container .text-box h3 {
    font-size: 30px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 10px;
}

@media (max-width: 900px) {
    section h1 {
        font-size: 2rem;
        text-align: center;
    }

    section .text-container {
        flex-direction: column;
    }
}

.reveal {
    position: relative;
    opacity: 0;

}

.reveal .image1 {
    width: 37.759vw;
    position: relative;
    top: 30px;
}

.reveal.active {
    opacity: 1;
}

.active.fade-bottom {
    animation: fade-bottom 1s ease-in;
}

.active.fade-left {
    animation: fade-left 1s ease-in;
}

.active.fade-right {
    animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
@media all and (min-width: 1000px) and (max-width: 1500px) {
    .detail-card-inspire {
        font-size: 1.1vw!important;

    }
    .detail-card-inspire.service{
        height: 160px;
    }
}
@media all and (min-width: 769px) and (max-width: 999px) {
    .card-review{
        width: 100% !important;
    }
    .detail-card-inspire {
        font-size: 2vw!important;
    }
    .title-cookie{
        font-size: 2vw!important;
    }
    .cookies{
        height: 98px!important;
    }
    .detail-card-inspire.service {
        height: unset;
    }
    #p{
        line-height: 3vw!important;
        font-size: 2vw!important;
    }
}

@media all and (min-width: 936px) and (max-width: 1300px) {
    .card-inspire {
        height: auto !important;
        gap: 30px!important;
    }

    section {
        top: 60px !important;
        height: unset !important;
    }

    .topnav a {
        font-size: 1.2vw !important;
    }

    .detail-card-inspire {
        font-size: 2vw;
    }
    .detail-card-inspire.service{
        height: unset;
    }

    #p {
        font-size: 2vw !important;
        line-height: 3vw !important;
    }

    .bloc-card {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .bloc-card-mission {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .card-container {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .card-review .comment .comment-detail {
        font-size: 1.5vw !important;
        line-height: 2.5vw !important;
    }
    .title-cookie{
        font-size: 1.5vw!important;
    }
    .topnav{
        border-bottom-right-radius: 250px 52px;
        border-bottom-left-radius: 250px 52px;
    }
    .topnav a{
        font-size: 1.2vw!important;
    }
    #lng {
        right: 0!important;
    }
    .topnav{
        border-bottom-right-radius: 250px 50px;
        border-bottom-left-radius: 250px 50px;
        gap: 30px;
    }
    .ant-select-selection-item{
        font-size: 1.2vw!important;
    }

    .card-review {
        width: 35vw!important;
    }

    .card-review .blc-top .txt-blc h2 {
        font-size: 1.1vw !important;
    }

    .card-review .blc-top .txt-blc p {
        font-size: 1.1vw !important;
    }

    .card-title {
        font-size: 1.4vw;
    }

    .card-inspire .botDiv .title .second {
        bottom: 2px;
        font-size: 1.7vw !important;
    }

    .card-inspire .botDiv .title .last_char {
        bottom: 2px;
        font-size: 1.5vw !important;
    }

    .card-title.g::first-letter {
        font-size: 1.5vw;
    }

    .card-title.r::first-letter {
        font-size: 1.5vw;
    }

    .card-title.a::first-letter {
        font-size: 1.5vw;
    }

}

@media all  and (min-width: 769px) and (max-width: 935px) {
    .reveal .image1 {
        object-fit: contain;
        top: unset;
    }

    .card-inspire {
        padding: 2.708vw 3.302vw 1.093vw;
    }

    .card-review .comment .comment-detail {
        font-size: 1.5vw !important;
        line-height: 2.5vw !important;
    }

    .card-review {
        width: 30vw !important;
    }

    .card-review .blc-top .txt-blc h2 {
        font-size: 1.1vw !important;
    }

    .card-review .blc-top .txt-blc p {
        font-size: 1.1vw !important;
    }

    #p {
        font-size: 1.2vw;
    }

    .card-title {
        font-size: 1.4vw;
    }

    .card-inspire .botDiv .title .second {
        bottom: 2px;
        font-size: 1.7vw !important;
    }

    .card-inspire .botDiv .title .last_char {
        bottom: 2px;
        font-size: 1.5vw !important;
    }

    .card-title.g::first-letter {
        font-size: 1.5vw;
    }

    .card-title.r::first-letter {
        font-size: 1.5vw;
    }

    .card-title.a::first-letter {
        font-size: 1.5vw;
    }

    section {
        top: 60px !important;
        height: unset !important;
    }

    .bloc-card {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .bloc-card-mission {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    .card-container {
        grid-template-columns: repeat(2, 1fr) !important;
    }


}

@media all and (max-width: 768px ) {
    .tech-image1 {
        width: 20vw !important;
        height: 6.5vh !important;
        object-fit: contain !important;
    }
    .cookies{
        height: 200px!important;
        align-items: center!important;

    }
    #rcc-confirm-button{
        position: relative;
        left: 88px;
        margin: 0 auto;

    }
    .title-cookie{
        font-size:4vw!important;
    }

    .backend {
        left: unset !important;
    }

    .tech-image {
        width: 20vw;
    }

    .clients-image {
        width: 50px;
    }

    #mission {
        padding-top: 4.6vh;
    }

    #second {
        gap: unset !important;
    }

    .left-inspire {
        left: unset;
    }

    li {

        font-size: 3.8vw;
        width: 90%;
    }

    ul {
        font-size: 3.8vw;
        line-height: 7.354vw;
    }

    section:nth-child(2) {

        background-size: contain;
    }

    .card-inspire .botDiv .title .second {
        font-size: 5vw;
    }

    .card-inspire .botDiv .title .last_char {
        font-size: 5vw;
    }

    .card-title.g::first-letter {
        font-size: 5vw;
    }

    .card-title.r::first-letter {
        font-size: 5vw;
    }

    .card-title.a::first-letter {
        font-size: 5vw;
    }

    section:nth-child(4) {
        /*color: #e0ffff;*/

        background-size: contain;
    }

    section:nth-child(5) {

        background-size: contain;
    }

    section:nth-child(6) {

        background-size: contain;
    }

    .items-contact {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    section:nth-child(4) {
        width: 100% !important;
        align-self: unset !important;
    }

    section:nth-child(5) {
        flex-direction: column !important;
        padding: 20px;
    }

    .card-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: auto;
        left: unset !important;
        padding: 20px;
    }

    .leftCard {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .card-title {
        font-size: 3.3vw;

    }

    .card-details {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .sub-title-service {
        font-size: 3vw;
    }

    .blc-text {
        font-size: 4.6vw;
    }

    .detail-text {
        font-size: 15px;
        width: 85%;
        text-align: justify !important;
        line-height: 2.854vh;

    }

    html {
        padding: 0px !important;
    }

    #p {
        font-size: 4vw !important;
        line-height: 6.354vw !important;
        left: unset !important;
        text-align: justify !important;
        padding: 20px;
        font-family: Poppins regular, serif;


    }

    .left-sect {
        align-items: center;
        width: unset;
        padding: 20px;
        /*gap: 5vh;*/
    }


    .card-details p {
        font-size: 3.256vw;
        transform: translateX(-100vw);
        visibility: hidden;
    }

    .card-details h2 {
        font-size: 3.256vw;
        transform: translateX(-100vw);
        visibility: hidden;
    }


    .logoCount {
        height: 16.042vw;
        width: 16.042vw;
    }

    .countPlus {
        font-size: 6.684vw;
        line-height: 6.8vw;
    }

    .count {
        font-size: 6.684vw;
        line-height: 6.8vw;
    }

    .card.active .card-details p {
        visibility: visible;
        transition: transform ease-in-out 0.5s;
        transform: translateX(0vw);
        transition-delay: 0.5s;
        right: 10px;
        border-radius: 50px;
        cursor: pointer;
        font-size: 3.55vw;
        font-family: Poppins regular, serif;
    }

    .title-section.active h1 {
        visibility: visible;
        transition: transform ease-in-out 0.6s;
        transform: translateX(0vw);
        transition-delay: 0.6s;
        cursor: pointer;

    }

    .title-section.expertise.active h1::before {
        background: #f07f18;
    }

    .title-section.mission.active h1::before {
        background: #f07f18;
    }

    .title-section.secteur.active h1::before {
        background: #f07f18;
    }

    .title-section h1 {
        /*visibility: hidden;*/
        /*transform: translateX(100vw);*/
    }

    .card.active .card-details h2 {
        visibility: visible;
        transition: transform ease-in-out 0.2s;
        transform: translateX(0vw);

        transition-delay: 0.6s;
        right: 10px;
        border-radius: 50px;
        cursor: pointer;
    }

    .titleCount {
        font-family: Poppins medium, serif;
        font-size: 4.812vw;
    }

    section {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: auto;
        padding-top: 7.5vh;
        align-items: center !important;

    }

    h3 {
        font-size: 3.743vw;
        line-height: 5.614vw;
    }

    .about_us_container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;
    }

    #first:nth-child(1) {
        flex-direction: column-reverse;
    }

    #first {
        display: flex;
        flex-direction: column-reverse;
        height: 100%;
        width: 100%;
        /*padding: 10px 20px;*/
        position: relative;

    }

    #propos {
    }

    #second {
        display: flex;
        flex-direction: column-reverse;
        /*height: 100vh;*/
        width: 100%;
        /*padding: 0px 20px*/
    }

    #expertise {
    }

    .reveal .image1 {
        width: 100%;
        padding: 20px;
        top: unset;
    }


    .bloc-inspire {
        display: flex;
        padding-top: unset;
        flex-direction: column-reverse;
        gap: 20px;
        align-items: center;
    }

    .card-inspire {
        border-radius: 2.673vw;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        height: auto;
        padding: 5.882vw;
        width: 100%;
        /*width: 69.518vw;*/

    }

    .bloc-card-mission .card-inspire {
        height: max-content;
        padding: 10px;
        width: 100%;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    }

    .title-card-inspire {
        font-size: 14px;
    }

    .detail-card-inspire {
        font-size: 3.8vw;
        line-height: 6.354vw;
        height: unset!important;
    }
    .cookies{
        height: 233px !important;
    }
    .title-cookie{
        font-size: 3.5vw!important;

    }
    #rcc-confirm-button{
        left: unset;
    }

    .bloc-card {
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        /*width: 85%;*/
    }

    .bloc-card-mission {
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;

    }

    .title-1 {
        font-size: 5.347vw;
        line-height: 8.021vw;
        text-align: center;
    }

    .title-1:before {
        height: 0.977vw;
        left: 18.5vw;
        top: 6.5vw;
        width: 19.708vw;
    }

    .title-2 {
        font-size: 5.347vw;
        line-height: 8.021vw;
        text-align: center;

    }

    .title-2:before {
        height: 0.977vw;
        left: 13vw;
        top: 6.5vw;
        width: 19.708vw;
    }

    .primary-btn {
        border-radius: 7.719vw;
        font-size: 4.812vw;
        height: 12.032vw;
        padding: 0 9.358vw;
        margin: unset;
        position: unset;
    }

    .countContainer {
        display: flex;
        flex-direction: column;
        /*align-items: baseline !important;*/
        gap: 30px !important;
        width: 50% !important;
        position: relative;
        top: 20px;


    }

    .title-propos {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 5.347vw;
        line-height: 10.16vw;
    }

    .title-service {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 5.347vw;
        line-height: 10.16vw;
    }

    .img-inspire {
        width: 300px;
        height: 300px;
        /*display: none;*/

    }

    .img-mission {
        height: 300px;
    }

}


