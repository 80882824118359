.mainContainer{
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .offre_details{
    display: flex;
    flex-direction: column;
    gap: 10px;
    .header{

      .titre{
        font-weight: bold;
        color: #DB3A00;
        text-decoration: none;
        text-align: left;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        font-size: 30px;
      }
      .image{
        width: 600px;
        height: 500px;
      }
    }
    .body{
      display: flex;
      flex-direction: column;
      text-align: left;
      li{
        text-align: justify;
        font-size: 18px;
      }
      h2{
        font-weight: bold;
        font-size: 20px;
        text-align: left;
        padding-bottom: 10px;
      }
      .information{
        text-align: justify;
        font-size: 18px;
      }
    }
  }
  .image{
    width: 600px;
    height: auto;
    object-fit: fill;
  }


}
