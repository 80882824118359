.copy-right {
  padding:10.5px!important;
  height: max-content;
  //padding: 10px;
  background-color: #4981be9c;
  align-items: center;
  align-content: center;
  .container{
    display: flex;
    padding-left: 20px;
    justify-content: space-between;
    .left{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      gap: 10px;
      img{
        width: 150px;
        height: 50px;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .contact-list{
        z-index: 9999999999999;
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 15px;
        img{
          width: 25px;
          height: 20px;
        }

      }
      .linked{
        padding-left: 5px;
        display: flex;
        flex-direction: row-reverse;
        position: relative;
        align-items: center;
        gap: 10px;
      }
    }
  }
  .contact-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    h1{
      font-size: 1.1vw;
      color: white;
    }
    .localisation{
      display: flex;
      align-items: center;
      gap: 3px;

      img{
        width: 25px;
        height: 25px;
        //border-radius: 50px;
      }
    }
  }
  .center-horizontally{
    display: flex;
    align-items: center;
  }

  p {
    color: white;
    font-family: "Poppins medium", serif;
    font-size: 15px;
    //margin: 0 1.385vw 0 0;
    position: relative;
    top: 9px;
  }
  .address1{
    margin: unset!important;
    font-weight: bold;
    font-size: 21px;
  }
  .address{
    font-size: 15px;
    position: relative;
    top:2.2px
  }


  .s-media {
    grid-gap: 0.52vw;
    position: relative;
    top: 4px;


    a {
      width: 1.5vw;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

/**** responsive ****/

@media screen and (max-width: 767px) {
  .copy-right {
    height: auto;
    padding: 7.866vw 0;
    .container{
      padding-left: 10px;
    }
    .center-horizontally{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    p {
      font-size: 3vw;
      text-align: justify;
    }
    .address {
      font-size: 3vw!important;

    }
    .address1 {
      font-size: 5vw!important;
      width: 18vw;
    }

    .container {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      .right{
        width: 100%;
        gap: 10px;
        .contact-list{
          padding-left: 5px;
          img{
            width: 20px;
            height: 20px;
          }
        }

        .linked{
          gap: 20px;
          align-items: center;
          position: relative;
          padding-left: unset;
          //left: 10px;

        }
      }
      .contact-container{


        .localisation{
          img{
            width: 20px;
            height: 20px;
          }
        }
      }


      .center-horizontally {
        flex-direction: column;
        gap: 10px;
        //grid-gap: 5.333vw;

        .s-media {
          flex-direction: row;
          grid-gap: 2.666vw;
          img{
            width: unset;
          }


          a {
            width: 12.8vw;
            height: 12.8vw;
            img{
              width: 12vw;
              height: 12vw;
              padding-bottom: 5px;

            }
          }

        }
      }
    }
  }
}
