.card-container1{
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  //height: 14.8vh;
  z-index: 99;
  width: 100%;
  .ant-card-head{
    border-bottom: unset;
  }
  .ant-card-head-title{
    font-size: 20px;
    font-weight: bold;
  }
  .ant-card-body{
    padding: 0 20px!important;
  }
  .location{
    color: #000;
    text-decoration: none;
    font-size: 14.5px;
    text-transform: uppercase ;
    font-weight: bold;


  }
  .level {
    font-size: 15.5px;
    text-transform: uppercase ;

  }
  .date {
    color: #000;
    font-size: 14.5px;
    text-transform: uppercase ;
  }
}
@media screen and (max-width: 767px) {
  .card-container1{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-right: 20px;


    .ant-card-head-title{
      font-size: 3.256vw;
      font-family: Poppins bold, serif;
      text-transform: uppercase;




    }
    .location{
      margin-bottom: 20px;
      color: #000;
      text-decoration: none;
      padding-right: 32px;
      font-size: 3.15vw;
      line-height: 15px;
      text-transform: uppercase ;
      font-weight: bold;


    }
    .level {
      margin-bottom: 20px;
      padding-right: 32px;
      font-size: 3.1vw;
      line-height: 15px;
      text-transform: uppercase ;

    }
    .date {
      margin-bottom: 20px;
      color: #000;
      padding-right: 32px;
      font-size: 2.9vw;
      line-height: 15px;
      text-transform: uppercase ;
    }
  }

}
