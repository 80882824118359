
.clients {
  padding: 6.921vw 0 0;

  p {
    &.descript-c {
      max-width: 48.125vw;
      margin: 2.171vw 0 4.677vw;
      font-family: "Poppins regular", serif;
      font-size: 1.041vw;
      line-height: 1.822vw;
    }
  }

  .list-client {
    width: 50%;
    align-self: center;
    .swiper {
      .swiper-pagination-bullets {
        bottom: 0;

        .swiper-pagination-bullet {
          width: 1.093vw;
          height: 0.572vw;
          border-radius: 1.25vw;
          margin: 0 0.15625vw;
          background-color: RGBA(106, 237, 255, 0.23);
          opacity: 1;

          &.swiper-pagination-bullet-active {
            background-color: white;
          }
        }
      }

      .swiper-wrapper {
        align-items: center;
        //animation: scroll-anim  7s linear infinite;
        //transform: translateX(0);
        .clients-image{
          height: 100px;
          width: 100px;
        }


        .swiper-slide {
          width: auto !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
          border-radius: 0.52vw;
          box-sizing: border-box;        }
      }
    }

    img {
      &.client1 {
        width: 12.343vw;
      }

      &.client2 {
        width: 8.75vw;
      }

      &.client3 {
        width: 7.187vw;
      }

      &.client4 {
        width: 7.760vw;
      }

      &.client5 {
        width: 10.885vw;
      }

      &.client6 {
        width: 9.895vw;
      }

      &.client7 {
        width: 9.843vw;
      }

      &.client8 {
        width: 5.052vw;
      }

      &.client9 {
        width: 5.052vw;
      }

      &.client10 {
        width: 6.387vw;
      }

      &.client11 {
        width: 5.885vw;
      }
    }
  }
}

@keyframes scroll-anim {
  100%{
    transform: translateX(-50%);
  }
}


/**** responsive ****/

@media screen and (max-width: 767px){
  .clients {
    //padding: 19.385vw 0 0;

    p {
      &.descript-c {
        max-width: 100%;
        margin: 6.417vw 0 9.625vw;
        font-size: 3.743vw;
        line-height: 8.021vw;
      }
    }

    .list-client {
      width: 100%;
      .swiper {
        .swiper-wrapper {
          .clients-image{
            height: 70px;
            width: 60px;
          }
        }
      }


      img {
        &.client1 {
          width: 40.221vw;
        }

        &.client2 {
          width: 28.51vw;
        }

        &.client3 {
          width: 23.41vw;
        }

        &.client4 {
          width: 25.286vw;
        }

        &.client5 {
          width: 35.47vw;
        }

        &.client6 {
          width: 32.245vw;
        }

        &.client7 {
          width: 32.074vw;
        }

        &.client8 {
          width: 16.462vw;
        }

        &.client9 {
          width: 16.462vw;
        }

        &.client10 {
          width: 20.812vw;
        }

        &.client11 {
          width: 19.176vw;
        }
      }
    }

  }
}
