.contact {
  background-image: url("https://images.pexels.com/photos/3183197/pexels-photo-3183197.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: no-repeat!important;
  background-size: cover;
  height: 100vh;
  &.career{
    height: 85.25vh;
    background-image: url("https://images.unsplash.com/photo-1495653797063-114787b77b23?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  }

.jobs{
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: max-content;
  justify-content: space-between;
  width: 90%;
  margin: auto;

  .job_container-left{
    width: 40%;
    //height: 60vh;
    z-index: 9999;
    position: relative;
    img{
      width: 100%;
      border-radius: 0 0 0.7vw 0.7vw;
      height: 100%;
      object-fit: cover;
    }
  }
}
.job_container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  height: 100%;
  justify-content: space-between;
}

  .card-contact {
    padding: 1vw 4.687vw 5vw;
    width: 97.677vw;
    box-sizing: border-box;
    box-shadow: 0.156vw 0.312vw 1.562vw white;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    border-radius: 0 0 0.7vw 0.7vw;
    text-align: left;
    z-index: 1;
    background-color: white;
    opacity: 0.91;
    top: 19vh;
    height: fit-content;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 97.677vw;
      height: 3.906vw;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -3vw;
      background-color: white;
      border-radius: 0.7vw 0.7vw 0 0;
    }
  }

  .Candidature {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 10px;
    height: auto;
    box-sizing: border-box;
    position: relative;
    border-radius: 0 0 0.7vw 0.7vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    text-align: left;
    z-index: 1;
    //padding: 0 20px;
    .form{
      position: relative;
      padding: 0 16px;
      width: 100%;
      height: 100%;
      .form_container{
        display: flex;
        position: relative;
        width: 87%;
        flex-direction: row;
        justify-content: space-between;
        gap: 10px;
        //grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        .ant-form-item {
          margin-bottom: 10px!important;
        }
        .ant-upload{
          height: 113px;
        }
        input{
          width: 270px;
        }
        textarea{
          width: 280px;
        }


      }
      .input {
        position: relative;


        &__label {
          position: absolute;
          left: 0;
          top: 0;
          padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
          margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
          background: pink;
          white-space: nowrap;
          transform: translate(0, 0);
          transform-origin: 0 0;
          background: var(--color-background);
          transition: transform 120ms ease-in;
          line-height: 1.1;
        }
        &__field {
          box-sizing: border-box;
          display: block;
          width: 100%;
          padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
          color: currentColor;
          background: transparent;
          border: unset;
          border-bottom: 1px solid gray;
          &:focus-visible{
            border-top: unset!important;
            border-right: unset;
            border-left: unset;
          }

          &:focus,
          &:not(:placeholder-shown) {
            & + .input__label {

              transform: translate(.25rem, -65%) scale(.8);
              //color: var(--color-accent);
            }
          }
        }
      }

    }
  }
  .card-contact2 {
    width: 100% !important;
    box-sizing: border-box;
    box-shadow: 0.156vw 0.312vw 1.562vw white;
    position: relative;
    border-radius: 0 0 0.7vw 0.7vw;
    text-align: left;
    z-index: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 75.677vw;
      height: 3.906vw;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -3vw;
      background-color: white;
      border-radius: 0.7vw 0.7vw 0 0;
    }
  }
  .contact-content {
    padding: 0 0 4.166vw;

    .blc-img {
      width: 100%;
      height: 39.687vw;
      position: relative;

      .blc-abs {
        position: absolute;
        width: 100%;
        top: 13.4vw;

        .next-title {
          right: 0;
        }

        .prev-title {
          left: 0;
        }

        .prev-title,
        .next-title {
          position: absolute;
          opacity: 0.3;
          top: 0;
        }

        h1 {
          color: white;
          font-family: "Poppins bold", serif;
          font-size: 3.385vw;
        }

        p {
          color: white;
          font-family: "Poppins extraLight", serif;
          font-size: 1.822vw;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .swiper-button-prev.swiper-button-disabled {
      display: none !important;
    }
  }

  /**** style swiper ****/

  .swiper-contact {
    .swiper-button-next,
    .swiper-button-prev {
      top: 16.458vw;
      width: 3.02vw;
      height: 2.031vw;
      border-radius: 1.093vw;
      cursor: pointer;
      border: 0.052vw solid #00bdd6;
      background-color: rgba(0, 0, 0, 0);
      background-repeat: no-repeat;
      background-size: 0.919vw;
      background-position: center;

      &:hover {
        background-color: #00bdd6;
      }

      &:after {
        content: "";
      }
    }

    .swiper-button-prev {
      left: 15.52vw;
      background-image: url("../../assets/img/arrowRight.svg");
      transform: rotate(180deg);
      &:hover {
        background-image: url("../../assets/img/arrowRight.svg");
      }
    }

    .swiper-button-next {
      right: 15.52vw;
      background-image: url("../../assets/img/arrowRight.svg");
      &:hover {
        background-image: url("../../assets/img/arrowRight.svg");
      }
    }
  }
}
@media screen and (min-width: 769px) and ( max-width: 1300px) {
  .contact {
    padding: 10.75vh  17px 5px 43px!important;
    height: unset!important;
    &.career{
      background-position-x:center;
      height: 100vh!important;
      .item-contact{
        width: 50vw;
        height: 50vw;
        .mask {
          width: 45.3vw;
          height: 50.46vw;
        }
      }

    }
    .formContainer{
      display: flex;
      flex-direction: column-reverse;
      .information{
        width: 100%;
      }
      .contact_image{
        width: 100%;
      }
    .form{
      width: 100%;
    }

    }

    .jobs{
      //flex-direction: column;
      gap: 15px;

      .job_container-left{
        width: 40%;
        height: 50vh;
        z-index: 9999;
        position: relative;
        img{
          width: 100%;
          border-radius: 0 0 0.7vw 0.7vw;
          height: 100%;
        }
      }
    }
    .job_container{
      display: flex;
      flex-direction: column;
      width: 60%;
      gap: 20px;
    }
    .Candidature{
      width: 100%;
      height: max-content;
      max-height: unset;
      .form_container{
        flex-direction: column;
        width: 100%;
      }
    }
    .card-contact{
      width: 82vw;
      top: 10vh!important;
      bottom:4vh;
      left: 8px;
      height: auto;

      &::before{
        width: 82vw;
      }
    }
    .jobs{
      flex-direction: column-reverse;
      height: fit-content;
      .job_container-left{
        width: 100%;
        height: 40vh;
        position: relative;
        img{
          object-fit: cover;
        }
      }
      .job_container{
        width: 100%;
        gap: 25px;
      }


    }

    .card-contact2{
      width: 100vw;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      &::before{
        width: 82vw;
      }
    }
    .contact-content {
      .blc-img {
        .blc-abs {
          h1 {
            font-size: 5.385vw;
          }
          p {
            font-size: 2.822vw;
          }
        }
      }
    }
    .swiper-contact{
      .swiper-button-prev , .swiper-button-next{
        top: 21.458vw !important;
        width: 4.02vw;
        height: 3.031vw;
        background-size: 1.919vw;
        border-radius: 2.093vw;
      }
    }
  }

}
@media screen and (max-width: 767px) {
  .contact {
    height: unset!important;
    &.career{

      background-position-x:center;
      height: 100vh;
    }
    .jobs{
      //flex-direction: column;
      gap: 15px;

      .job_container-left{
        width: 40%;
        height: 50vh;
        z-index: 9999;
        position: relative;
        img{
          width: 100%;
          border-radius: 0 0 0.7vw 0.7vw;
          height: 100%;
        }
      }
    }
    .job_container{
      display: flex;
      flex-direction: column;
      width: 60%;
      gap: 20px;
    }
    .Candidature{
      width: 100%;
      height: max-content;
      max-height: unset;
      .form_container{
        display: flex;
        flex-direction: column!important;
        justify-content: space-evenly!important;
        gap: unset!important;
        input{
          width: 280px;
        }
        textarea{
          width: 280px;
          resize: none!important;
        }
        .left{
          position: relative;
          width: 50%;
        }
        .right{
          width: 50%;
          position: relative;
        }

      }


    }
    .card-contact{
      width: 82vw;
      top: unset;
      bottom:4vh;
      left: 8px;
      height: auto;

      &::before{
        width: 82vw;
      }
    }
    .jobs{
    flex-direction: column-reverse;
      height: fit-content;
      .job_container-left{
        width: 100%;
        height: 40vh;
        position: relative;
        img{
          object-fit: cover;
        }
      }
      .job_container{
        width: 100%;
        gap: 25px;
      }


    }

    .card-contact2{
      width: 100vw;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      &::before{
        width: 82vw;
      }
    }
    .contact-content {
      .blc-img {
        .blc-abs {
          h1 {
            font-size: 5.385vw;
          }
          p {
            font-size: 2.822vw;
          }
        }
      }
    }
    .swiper-contact{
      .swiper-button-prev , .swiper-button-next{
        top: 21.458vw !important;
        width: 4.02vw;
        height: 3.031vw;
        background-size: 1.919vw;
        border-radius: 2.093vw;
      }
    }
  }
}
