.reviews {
  padding: 8vw 0 0;
  height: auto;

  .title-review {
    margin: 0 0 3.765vw;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 6.645vw;
      height: 5.859vw;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      right: 27vw;
      top: 1vw;
    }
  }

  .blc-cards {
    padding: 0 9.0625vw 0;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: flex;
    //cursor: pointer;
    gap: 20px;


    .swiper {
      padding: 20px;
      width: 50vw;
      .swiper-slide{
        //width: auto!important;

        //box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

      }
      .swiper-wrapper{
      }
      .swiper-button-prev{
        left: var(--swiper-navigation-sides-offset,10px);
        color: #4981be;

      }
      .swiper-button-next{
        color: #4981be;

      }


      .swiper-pagination-bullets {
        bottom: 0;
        display: none;

        .swiper-pagination-bullet {
          width: 1.093vw;
          height: 0.572vw;
          border-radius: 1.25vw;
          margin: 0 0.15625vw;
          background-color: RGBA(106, 237, 255, 0.23);
          opacity: 1;

          &.swiper-pagination-bullet-active {
            background-color: white;
          }
        }
      }
    }
  }
}

/**** responsive ****/
@media screen and (max-width: 1200px) and (min-width: 768px){
  .reviews {
    .blc-cards {
      .swiper {
        width: 100%;
        .swiper-button-prev{
          left: var(--swiper-navigation-sides-offset,-3px);
        }
        .swiper-pagination-bullets {
          bottom: 0;
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .reviews {
    .blc-cards {
      padding: 0px 4.625vw 0;
      //background-image: url("../../assets/icons/bg-reviewMob.png");
      background-position: bottom;
      width: 100%;
      height: inherit;




      .swiper {
        width: 100%;
        .swiper-button-prev{
          left: var(--swiper-navigation-sides-offset,-3px);
        } .swiper-button-next{
          right: var(--swiper-navigation-sides-offset,-3px);
        }
        .swiper-pagination-bullets {
          bottom: 0;
          display: none;
        }
      }
    }
  }
}
