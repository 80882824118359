.contact-section {
  padding: 4.947vw 0 4.817vw;
  //background-image: url("../../assets/images/bg-contact.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  .title-container{
    margin-bottom: 90px;
  }

  p {
    &.text-contact {
      max-width: 41.666vw;
      font-family: "Poppins regular", serif;
      font-size: 1.041vw;
      line-height: 1.562vw;
      color: #2d2d2d;
      margin: 1.354vw 0 3.125vw;
    }
  }
}


/**** responsive ****/
@media screen and (max-width: 767px) {
  .contact-section {
    padding: 12.8vw 0 8.693vw;
    background-image: none;

    .container {
      padding: 0 4vw;
    }

    p {
      &.text-contact {
        max-width: 100%;
        font-size: 3.733vw;
        line-height: 6.933vw;
        margin: 2.666vw 0 12.266vw;
        align-items: center;
      }
    }

    .items-contact {
      flex-direction: column;
      grid-gap: 3.2vw;
    }
  }
}
