.card-review {
  height: max-content;
  width: 90%;
  background-color: white;
  padding: 20px;
  //border: 1px solid black;
  border-radius: 0.520vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: relative;
  //left: 37px;
  //border: 1px solid;

  .blc-top {
    display: flex;
    gap: 20px;
    margin-right: 0;
    margin-left: 0;
    align-items: center;

    .div-img{
      width: 5.5vw;
      height: 5.5vw;
      border : 1px solid #4981be;
      border-radius: 10px;
      display: flex;
      align-items: center;

      img {
        width: 4.427vw;
        height: 4.427vw;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
      }
    }


    .txt-blc {
      text-align: left;
      h2 {
        color: black;
        text-align: left;
        font-family: Poppins bold, serif;
        margin: 0 0 0.2vw;
        text-transform: uppercase;
        font-size: .8vw;

      }
      .subTitleReview{
        font-size: 0.8vw;
        font-style: italic;
      }
      p {
        font-family: "Poppins regular", serif;
        color: var(--AGED-GREY);
        font-size: 0.937vw;
        line-height: 0.979vw;
      }
    }
  }

  .comment {
    //padding: 1.640vw 0 0;
    position: relative;
    height: max-content;
    .comment-detail {
      font-family: Poppins regular, serif;
      color: #2d2d2d;
      font-size: 0.9vw;
      text-align: justify;
    }

    p {
      font-family: Poppins regular, serif;
      line-height: 1.562vw;
      padding: 7px;
      color: #2d2d2d;
      font-size: 1.1vw;
      text-align: justify;
    }
  }
}

@media screen and (max-width: 1400px) and (min-width: 768px){
  .card-review {

    .blc-top {
      .div-img{

        img {

        }

      }
      .txt-blc {
        h2 {

        }

        p {

        }
      }
    }
    .comment {
      .comment-detail{
        font-size: 1.1vw;

      }

      &::after {

      }

      p {
      }
    }
  }
}
/**** responsive ****/

@media screen and (max-width: 767px){
  .card-review {
    width: 73.347vw!important;
    border-radius: 2.673vw;
    padding: 5.08vw;
    position: relative;
    //left: 20px;
    .blc-top {
      grid-gap: 3.262vw;
      .div-img{
        width: 16vw;
        height: 16vw;
        img {
          width: 14.55vw;
          height: 14.55vw;
        }

      }
      .txt-blc {
        h2 {
          font-size: 3.743vw!important;
          line-height: 6.149vw!important;
        }
        .subTitleReview{
          font-size: 3.1vw!important;
          line-height: 5.149vw!important;
        }

        p {
          font-size: 3.208vw!important;
          line-height: 6.149vw!important;
        }
      }
    }
    .comment {
      //padding: 7.807vw 0 0;
      .comment-detail{
        font-family: Poppins regular, serif;
        font-size: 3.8vw!important;
        line-height: 6.354vw!important;
        text-align: justify;
      }

      &::after {
        width: 9.7vw;
        height: 7.197vw;
        top: 6vw;
      }

      p {
        font-size: 3.208vw;
        line-height: 6.951vw;
      }
    }
  }
}
