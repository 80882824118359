@import url('https://rsms.me/inter/inter.css');

:root {
  --color-light: white;
  --color-dark: #212121;
  --color-signal: #fab700;
  --color-background: var(--color-light);
  --color-text: var(--color-dark);
  --color-accent: var(--color-signal);
  --size-bezel: .5rem;
  --size-radius: 4px;
  line-height: 1.4;
  font-family: 'Inter', sans-serif;
  font-size: calc(.6rem + .4vw);
  color: var(--color-text);
  background: var(--color-background);
  font-weight: 300;
}
.contact {
  .formContainer{
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    .contact_image{
      width: 40%;
      img{
        width: 100%;
        height: 90%;
        object-fit: cover;

      }
    }
    .information{
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 22%;
      .contact-list{
        display: flex;
        gap: 10px;
        align-items: center;
      }
      .localisation{
        display: flex;
        gap: 10px;
        align-items: center;
        font-family: Poppins regular, serif ;

      }

    }

    .form{
      width: 40%;
      padding-left: 30px;

      .input {
        position: relative;


        &__label {
          position: absolute;
          left: 0;
          top: 0;
          padding: calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
          margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
          background: pink;
          white-space: nowrap;
          transform: translate(0, 0);
          transform-origin: 0 0;
          background: var(--color-background);
          transition: transform 120ms ease-in;
          line-height: 1.1;
        }
        &__field {
          box-sizing: border-box;
          display: block;
          width: 100%;
          padding: calc(var(--size-bezel) * 1.5) var(--size-bezel);
          color: currentColor;
          background: transparent;
          border: unset;
          border-bottom: 1px solid gray;
          &:focus-visible{
            border-top: unset!important;
            border-right: unset;
            border-left: unset;
          }

          &:focus,
          &:not(:placeholder-shown) {
            & + .input__label {

              transform: translate(.25rem, -65%) scale(.8);
              //color: var(--color-accent);
            }
          }
        }
      }
      h1{
        color: black;
        padding-bottom: 20px;
        text-align: left;
        position: relative;
      }
    }
  }
  .card-contact {
    padding: 1vw 4.687vw 5vw;
    width: 75.677vw;
    box-sizing: border-box;
    box-shadow: 0.156vw 0.312vw 1.562vw white;
    margin: -5vw auto 10.417vw auto;
    position: relative;
    border-radius: 0 0 0.7vw 0.7vw;
    text-align: left;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 75.677vw;
      height: 3.906vw;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: -3vw;
      background-color: white;
      border-radius: 0.7vw 0.7vw 0 0;
    }
  }
  .contact-content {
    padding: 0 0 4.166vw;

    .blc-img {
      width: 100%;
      height: 39.687vw;
      position: relative;

      .blc-abs {
        position: absolute;
        width: 100%;
        top: 13.4vw;

        .next-title {
          right: 0;
        }

        .prev-title {
          left: 0;
        }

        .prev-title,
        .next-title {
          position: absolute;
          opacity: 0.3;
          top: 0;
        }

        h1 {
          color: white;
          font-family: "Poppins bold", serif;
          font-size: 3.385vw;
        }

        p {
          color: white;
          font-family: "Poppins extraLight", serif;
          font-size: 1.822vw;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .swiper-button-prev.swiper-button-disabled {
      display: none !important;
    }
  }

  /**** style swiper ****/

  .swiper-contact {
    .swiper-button-next,
    .swiper-button-prev {
      top: 16.458vw;
      width: 3.02vw;
      height: 2.031vw;
      border-radius: 1.093vw;
      cursor: pointer;
      border: 0.052vw solid #00bdd6;
      background-color: rgba(0, 0, 0, 0);
      background-repeat: no-repeat;
      background-size: 0.919vw;
      background-position: center;

      &:hover {
        background-color: #00bdd6;
      }

      &:after {
        content: "";
      }
    }

    .swiper-button-prev {
      left: 15.52vw;
      background-image: url("../../assets/img/arrowRight.svg");
      transform: rotate(180deg);
      &:hover {
        background-image: url("../../assets/img/arrowRight.svg");
      }
    }

    .swiper-button-next {
      right: 15.52vw;
      background-image: url("../../assets/img/arrowRight.svg");
      &:hover {
        background-image: url("../../assets/img/arrowRight.svg");
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .contact {
    padding-top: 20vh!important;

    .formContainer {
      display: flex;
      flex-direction: column-reverse;
      .contact_image{
        width: 100%;
      }
      .information {
        width: 100%;

        p {
          color: rgba(73, 73, 81, 1);
          font-size: 3.7vw;
        }
      }
      .form {
        width: 100%;
        padding-left: unset;

        h1 {
          color: black;
          padding-bottom: 20px;
          text-align: left;
          position: relative;
        }
      }
    }
    .card-contact{
      width: 82vw;
      &::before{
        width: 82vw;
      }
    }
    .contact-content {
      .blc-img {
        .blc-abs {
          h1 {
            font-size: 5.385vw;
          }
          p {
            font-size: 2.822vw;
          }
        }
      }
    }
    .swiper-contact{
      .swiper-button-prev , .swiper-button-next{
        top: 21.458vw !important;
        width: 4.02vw;
        height: 3.031vw;
        background-size: 1.919vw;
        border-radius: 2.093vw;
      }
    }
  }
}
