
/* Add a black background color to the top navigation */
.ant-select-selection-item{
  font-size: 0.9vw;
  color:#808283 ;
}
.topnav {
  background-color: white;
  align-items: center;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  top: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 999999999;
  border-bottom-right-radius: 250px 40px;
  border-bottom-left-radius: 250px 40px;
  border-bottom: 2px solid #4981be ;
  img {
    width: 200px;
    height: 50px;
  }


}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: #808283;
  text-align: center;
  padding: 0px 10px;
  text-decoration: none;
  font-size: 0.9vw;
  cursor: pointer;

}
.items {
  gap: 5px;
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  #lng{
    border: unset;
    position: fixed;
    right: 5%;
    //right: 20px;
    //position: absolute;
    color: #808283;
    text-align: center;
    .select{
      border: unset;
      font-size: 0.9vw;
      color: #808283;
      option{
        padding-top: 5px;
      }
    }
  }


}

/* Change the color of links on hover */
.topnav a:hover {
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.true {
  //text-decoration: underline #4981be 3px;
  color: black;
  border-bottom:  #4981be solid 3px ;
}


/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

@media screen and (max-width: 768px) {
  .ant-select-selection-item{
    font-size: 3.5vw;
    color: black;
  }
  .topnav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom-right-radius: 75px 50px;
    border-bottom-left-radius: 75px 50px;
    border-bottom:  thick  solid #4981be ;
    img{
      width: 140px !important;
      height: 57px !important;
      position: fixed;
      top: 8px;
    }
    .fa-bars{
      width: 25px!important;
      height: 25px!important;
      position: fixed;
      right: 10px;
      top: 30px;
      z-index: 0;
    }
    .fa-bars-close{
      width: 20px!important;
      height: 20px!important;
      position: fixed;
      right: 3px;
      top: 30px;
      z-index: 0;
    }

  }
  .topnav a{
    display: none;


  }

    .topnav a:not(:first-child) {display: none;}

  .items {
    gap: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    #lng{
      border: unset;
      right: 11px;
      position: relative;
      color: #808283;
      text-align: center;
      #lang:focus-visible{
        outline: none!important;
        border: unset!important;
      }
      .select{
        border: unset;
        font-size: 3.5vw;
        color: #808283;
      }
    }


  }
  .topnav a.icon {
    float: right;
    display: block;
    position: absolute;
    right: 20px;
    top: 18px;
  }
}
@media screen and (max-width: 935px) {
  .topnav {
    border-bottom: unset;
    border-bottom-right-radius:unset;
    border-bottom-left-radius:unset;
    align-items: center;
    gap: 20px;
  #lng{
    right: 0;
  }
     a {
       font-size: 1.5vw;
     }
    .ant-select-selection-item{
      font-size: 1.5vw!important;
    }
    .items{
      width: 80%;
    }
    img{
      width: 10vw;
      height: 5vh;
    }
  }



}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 768px) {
  .topnav{
    border-bottom: 2px solid #4981be;
    height: 10vh;
  }

    .topnav.responsive {
    position: fixed;
    align-items: unset;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 99999999999999999999;


    .items{
      display: flex;
      flex-direction: column;
      background-color: white;
      gap: 25px;
      height: auto;
      width: 60%;
      right: 0;
      top: 0;
      z-index: 99999!important;
      box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
      padding: 20px;
      position: absolute;
    }
    img{
      width: 100px;
      height: 100px;
    }

  }
  .fa-navicon:before, .fa-reorder:before, .fa-bars:before{
    font-size: 6vw;

  }

  .topnav.responsive a.icon {
    position: absolute;
    right: -30px;
    top: 22px;


  }
  .topnav.responsive a  {
    float: none;
    display: block;
    text-align: left;
    color: black;
    background-color: white;
    width: 100px;
    font-size: 3.5vw!important;
    cursor: pointer;
  }
  .topnav .ant-select-selection-item{
    font-size: 3.5vw!important;
    right: 11px;
  }
}
