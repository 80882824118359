.item-contact {
  width: 26.041vw;
  position: relative;
  opacity: 0.85;
  height: 26.4vw;
  img {
    width: 100%;
    height: 100%;
  }
  .btn-crd {
    display: none;
  }

  .content-item {
    display: flex;
    width: 26.041vw;
    height: 15.041vw;
    position: absolute;
    top: 50%;
    left: 40%;
    z-index: 9;
    h1 {
      color: white;
      font-family: "Poppins bold", serif;
      font-size: 1.562vw;
      line-height: 2.395vw;
    }
  }
  .mask {
    display: flex;
    width: 26.041vw;
    height: 26.4vw;
    background: rgba(0, 0, 0, 0.49);
    position: absolute;
    top: 0;
  }

  &:hover {
    .btn-crd {
      display: flex;
      position: absolute;
      text-decoration: none;
      font-family: "Poppins medium", serif;
      width: 11.979vw;
      height: 2.916vw;
      font-size:1.537vw;
      line-height: 1.406vw;
      color:#1ac1dd;
      border: 0.052vw solid #1ac1dd;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 1.458vw;
      bottom: 5.677vw;
      &:hover {
        background-color: #1ac1dd;
        color: white;
      }
    }
    .mask {
      display: none;
    }
  }
}


/**** responsive ****/

@media screen and (max-width: 767px) {
  .item-contact {
    width: 85.581vw;
    height: 100%;
    img {
      width: 100%;
      height: 333px;
    }
    .content-item {
      width: 85.581vw;
      height: 85.581vw;
      h1 {
        font-size: 5.066vw;
        line-height: 1.733vw;
      }
    }
    .mask {
      width: 85.581vw;
      height: 99%;
    }

    &:hover {
      .btn-crd {
        width: 51.979vw;
        height: 10.916vw;
        font-size: 4vw;
        line-height: 4.406vw;
        border: 0.266vw solid #1ac1dd;
        border-radius: 12.458vw;
        bottom: 18.677vw;
      }
    }
  }
}
