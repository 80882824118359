
.title-section {
  text-align: left;


  p {
    font-family: "Poppins semiBold", serif;
    color: blue;
    font-size: 1.042vw;
    line-height: 1.563vw;
    text-align: center;
    text-transform: uppercase;

  }
  h1 {
    position: relative;
    right: 20px;
    color: black;
    font-family: Poppins bold, serif;
    font-size: 2.2vw;
    line-height: 2.9vw;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
    width: 48vw;

    &:before{
      background-color: #4981be;
      content: "";
      display: block;
      height: 0.677vw;
      left: 18.5vw;
      position: absolute;
      top: 2.5vw;
      width: 10.708vw;
      z-index: 9;
    }
    //text-decoration: underline  10px solid #4981be;

  }
}

/**** responsive ****/

@media screen and (max-width: 767px) {
  .title-section {
    p {
      font-size: 4.278vw;
      line-height: 6.684vw;

      &::before {
        width: 3.855vw;
        height: 1.604vw;
        margin: 0 1vw 0 0;
      }
    }
    h1 {
      font-size: 5.347vw;
      line-height: 10.160vw;
      width: 70vw;
      &:before{
        background-color: #4981be;
        content: "";
        display: block;
        height: 0.677vw;
        left: 25.5vw;
        position: absolute;
        top: 7.5vw;
        width: 26.708vw;
        z-index: 9;
      }
    }
  }
}
