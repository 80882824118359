body {
  margin: 0;
  font-family: Poppins medium,serif;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ant-tooltip {
  display: none !important;
}
@media screen and (min-width: 1300px) {
  :where(.swal2-container).swal2-center>.swal2-popup{
    /*background: black !important;*/
    position: relative;
    right: 10%;
    z-index: 999999999999999999999999999999999999999999999999999999999999!important;
  }
}
@media screen and (min-width: 769px) and ( max-width: 1300px) {
  :where(.swal2-container).swal2-center>.swal2-popup{
    /*background: black !important;*/
    position: relative;
    top: 60%;
    z-index: 999999999999999999999999999999999999999999999999999999999999!important;
  }
}
